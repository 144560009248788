import {createStyles} from '@mantine/core';

export default createStyles(theme => ({
  openAppButton: {
    color: theme.other.getTextColor(theme, 'text-0'),
    backgroundColor: 'transparent',
    backgroundImage: 'none',
    transition: 'transform .25s ease-out',
    '&:hover': {
      transform: 'translate(0, -5px)',
    },
  },
  buttonLeftIcon: {
    width: theme.spacing.md,
    marginTop: 1,
    '& svg': {
      stroke: 'currentColor',
    },
  },
  green: {
    color: theme.colorScheme === 'light' ? theme.colors.green[5] : theme.colors.green[6],
  },
  yellow: {
    color: theme.colorScheme === 'light' ? theme.colors.yellow[5] : theme.colors.yellow[6],
  },
  red: {
    color: theme.colorScheme === 'light' ? theme.colors.red[7] : theme.colors.red[8],
  },
  tos: {
    color: theme.other.getTextColor(theme, 'text-0'),
    fontWeight: 500,
  },
}));
