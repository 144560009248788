import {Anchor, Button, Center, Container, Group, Stack, Text, Title} from '@mantine/core';
import {Link, useNavigation} from '@remix-run/react';
import {IconArrowRight} from '@tabler/icons';
import {generateMetaTags} from '~/features/seo';
import useStyles from './index.styles';

import type {MetaFunction} from '@remix-run/node';

export const meta: MetaFunction = () => {
  return generateMetaTags(
    {
      title: 'Smart Delegation App',
      description: '',
    },
    {appendTitle: false}
  );
};

export default function LandingPage() {
  const {state} = useNavigation();
  const {classes} = useStyles();

  return (
    <Container size="md" px={20} pt={120}>
      <Group align="center" grow>
        <Stack>
          <Title order={1} mb={0} size={76}>
            Smart
            <br />
            Delegation
            <br />
            <span className={classes.green}>A</span>
            <span className={classes.yellow}>p</span>
            <span className={classes.red}>p.</span>
          </Title>
          <Text color="dimmed" mt={20} size="sm">
            By RockawayX Labs
          </Text>
        </Stack>
        <Center>
          <Stack spacing="xs" align="center">
            <Button
              classNames={{root: classes.openAppButton, leftIcon: classes.buttonLeftIcon}}
              component={Link}
              to="/app"
              mb="xs"
              size="xl"
              variant="gradient"
              loading={state !== 'idle'}
              rightIcon={<IconArrowRight size={22} stroke={2.5} style={{marginTop: 2}} />}
            >
              Open App
            </Button>
            <Text color="dimmed" size="xs">
              By using the app, you agree to our{' '}
              <Anchor className={classes.tos} component={Link} to="/terms-of-service">
                Terms of Service
              </Anchor>
              .
            </Text>
          </Stack>
        </Center>
      </Group>
    </Container>
  );
}
